import React from "react";
import history from '../history';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert'
import {
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup
} from "shards-react";



import server from "../Server/Server";
import Auth from "../Auth/Auth"
import DemoStudentLogin from "../components/demo-login/DemoStudentLogin"

export default class LoginLink extends React.Component {
  constructor(props){
    super(props);
    const queryParams = new URLSearchParams(window.location.search)

    this.state = {
      linkId: queryParams.get('id'),
      lessonName:"",
      authIdToken:"",
    };


    let headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('idToken')
      };
      this.updateStudentName = this.updateStudentName.bind(this);
      this.updateStudentSeat = this.updateStudentSeat.bind(this);
  }
  componentDidMount(){
      console.log('doin stuff')
      // Remove tokens and expiry time
      Auth.accessToken = null;
      Auth.idToken = null;
      Auth.expiresAt = 0;

      // Remove isLoggedIn flag from localStorage
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('expiresAt');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('idToken');
      localStorage.removeItem('sub');
      localStorage.removeItem('student_id');

      if(this.state.linkId === '') {
        this.setState({ linkId: localStorage.getItem('linkId') });
        history.replace(`${this.props.location.pathname}?id=${localStorage.getItem('linkId')}`);
      }
      else {
        localStorage.setItem('linkId', this.state.linkId);
      }
      var self = this;

      server.useLoginLink((response)=>{
        console.log("Logging in!");
        console.log(response);
        self.setState(
          {
            authIdToken : response.data.idToken
          });
        console.log(self.state.authIdToken);
  
  
        let expiresAt = (response.data.expiresIn * 1000) + new Date().getTime();
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('expiresAt', expiresAt);
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('idToken', response.data.idToken);
        localStorage.setItem('sub', response.data.sub);
        localStorage.setItem('student_id', response.data.studentId);
        localStorage.setItem('payload', JSON.stringify(response.data.payload));
  
        localStorage.removeItem('linkId');
  
        Auth.accessToken = response.data.accessToken;
        Auth.idToken = response.idToken;
        Auth.expiresAt = expiresAt;
        Auth.sub = response.data.sub;
/*
        if("courseId" in response.data)
        {
          history.push("/lesson/" + response.data.courseId);
        }
        else */
//        console.log("go to my courses first, course id is ", response.data.courseId);
        history.push("/my-courses");

      }, function(error){
        console.log("Error in useLoginLink in LoginLink", error);
      }, this.state.linkId)
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.location !== this.props.location) {
      console.log('updaaaaaate')
      this.setState({ linkId: localStorage.getItem('linkId') });
      history.replace(`${this.props.location.pathname}?id=${localStorage.getItem('linkId')}`);
    }
  }


  updateStudentName(evnt){
    this.setState({student_name: evnt.target.value});
  }

  updateStudentSeat(evnt){
    this.setState({student_seat: evnt.target.value});
  }




  render(){
    return (
      <ListGroup flush>
      </ListGroup>
    )
  }
}
