import React from "react";
import clsx from 'clsx';

import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Alert from 'react-bootstrap/Alert';
import EmailIcon from '@material-ui/icons/Email';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { withTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import {
  Row,
  Col,
} from "shards-react";

import server from "../../Server/Server";

const styles = theme => ({
  card: {
    marginBottom: '30px ',
  },

  title:{
    color: "DarkBlue",
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "40%",
  },
  instruction: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },

  button:{
    width:"45%",
    marginTop: '28px ',

  }
});

class UserAccountCardSignUp extends React.Component
{
  constructor(props) {
    super(props);
    this.state =
    {
      title: this.props.title,
      details:
      {
        firstName: "",
        lastName: "",
        email: "",
        phoneNum: "",
      },
      emailTaken: this.props.emailTaken,
      tooMany:this.props.tooMany,
      isEmail: false,
      fieldError: false,
      info_changed: false,
      isPhoneNumber:false,
      link: this.props.link,
      courseName: this.props.courseName,
    }
    this.state = {details:  this.props.details};

    this.setEmail = this.setEmail.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.checkEmail = this.checkEmail.bind(this);

    this.setFirstName = this.setFirstName.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);

    this.setLastName = this.setLastName.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);

    this.setPhoneNumber = this.setPhoneNumber.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.checkPhoneNumber = this.checkPhoneNumber.bind(this);

    this.submit = this.submit.bind(this);
  }

  componentDidUpdate(prevProps, prevState)
  {
    if(prevProps.details!=this.props.details)
    {
      this.setState({details: this.props.details});
      this.checkEmail(this.props.details.email);
      this.checkPhoneNumber(this.props.details.phoneNum);
      console.log("New Details", this.props.details);
    }
    if(prevProps.emailTaken!=this.props.emailTaken)
    {
      this.setState({emailTaken: this.props.emailTaken});
      this.setState({fieldError:true})
    }
    if(prevProps.tooMany!=this.props.tooMany)
    {
      this.setState({tooMany: this.props.tooMany});
    }
    if(prevProps.link!=this.props.link)
    {
      this.setState({link: this.props.link});
    }
    if(prevProps.courseName!=this.props.courseName)
    {
      this.setState({courseName: this.props.courseName});
    }
  }

  setPhoneNumber(value)
  {
    var dets = this.state.details;
    dets.phoneNum = value;
    this.setState({details: dets});
  }

  handlePhoneNumberChange(evnt)
  {
    this.setState({fieldError:false})
    this.checkPhoneNumber(evnt.target.value);
    this.setPhoneNumber(evnt.target.value);
  }
  
  checkPhoneNumber(value)
  {
    var re = RegExp('^(0|(\\+[0-9]{3}))[0-9]{9}$','g');
    var arr_result = re.exec(value);
    console.log("Arr_Result", arr_result);
  
    if (value == "")  {
      this.setState({isPhoneNumber: true})
    }
    else if(!arr_result)
    {
      this.setState({isPhoneNumber: false})
    }
    else {
      this.setState({isPhoneNumber: true})
    }
  }

  setEmail(value)
  {
    var dets = this.state.details;
    dets.email = value;
    this.setState({details: dets});
  }

  handleEmailChange(evnt)
  {
    this.setState({info_changed: true})
    this.props.changedEmail();
    this.setState({emailTaken:false})
    this.setState({fieldError:false})
    this.checkEmail(evnt.target.value);
    this.setEmail(evnt.target.value);
  }

  checkEmail(value)
  {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var arr_result = re.exec(value);
    console.log("Arr_Result", arr_result);
    if(!arr_result )
    {
      this.setState({isEmail: false})
    }
    else {
      this.setState({isEmail: true})
    }
  }

  setFirstName(value)
  {
    var dets = this.state.details;
    dets.firstName = value;
    this.setState({details: dets});
  }

  handleFirstNameChange(evnt)
  {
    this.setState({info_changed: true})
    this.setFirstName(evnt.target.value);
  }

  setLastName(value)
  {
    var dets = this.state.details;
    dets.lastName = value;
    this.setState({details: dets});
  }

  handleLastNameChange(evnt)
  {
    this.setState({info_changed: true})
    this.setLastName(evnt.target.value);
  }

  submit()
  {
    if (!this.state.isPhoneNumber || !this.state.isEmail || this.state.emailTaken)
    {
      this.setState({fieldError:true})
      console.log("Submit error");
      return
    }
    this.props.updateStudent(this.state.details, /*isDemo=*/ true);
    this.setState({info_changed:false})
  }

  render(){
    const classes = this.props.classes;
    const { t } = this.props;

    return(
      <Card className={classes.card}>
      {(this.state.details.demoStudent) &&
        <Alert variant = "warning">
          <Alert.Heading style={{color:"white"} }>{t("Welcome to the System!")}</Alert.Heading>
          <p>
            {t("firstEntry")}. <br/>
            {t("continueLearning")}. <br/>
            {t("futureLogin")}.
          </p>
        </Alert>
      }
      {(this.state.tooMany) &&
        <Alert variant = "warning">
          <Alert.Heading style={{color:"white"} }>{t("Your Account is blocked!")}</Alert.Heading>
          <p>
            {t("You've accessed your account too many times")}. <br/>
            {t("Contact system administrator or look for instructions in your mail for further instructions")}.
          </p>
        </Alert>
      }

      {this.state.fieldError &&
        <Alert variant = "dark">
          <Alert.Heading style={{color:"white"}}>{t("One of the Fields filled is wrong!")}</Alert.Heading>
            <p> {t("Check which field is marked with red or consult an administrator")}</p>
        </Alert>
      }
      
      {this.state.link &&
        <Alert variant="success">
          <Alert.Heading style={{color:"white"}}>{t("Update succesful!")}</Alert.Heading>
            <p> {t("Here's a link you can use to login in the future:")} <a href={this.state.link}>{this.state.link}</a></p>
            <p> {t("You can send it to yourself to save it for later:")}</p>
            
            <IconButton aria-label="email" size="medium" onClick={() => {
                const emailSubject = "Here's your login link!"
                const emailBody = `Hi!

                Here's a link you can use to log in to ${this.state.courseName}:
                
                ${this.state.link}
                
                Enjoy!`;
                const url = `mailto:${this.state.details.email}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
                console.log("UserAccountCardSignUp: email url ", url);
                window.open(url,'_blank');
                console.log("UserAccountCardSignUp: after window open ", url);
              }}>
              <EmailIcon fontSize="inherit" />
            </IconButton>
            <IconButton aria-label="whatsapp" size="medium" onClick={() => {
                const messageText = `Hi!

                Here's a link you can use to log in to ${this.state.courseName}:
                
                ${this.state.link}
                
                Enjoy!`;
                let parsedPhoneNum = this.state.details.phoneNum;
                if (parsedPhoneNum[0] === '0')
                  parsedPhoneNum = '972' + parsedPhoneNum.slice(3)
                else if (parsedPhoneNum[0] === '+')
                  parsedPhoneNum = parsedPhoneNum.slice(1)
                else
                  parsedPhoneNum = '';

                const url = `https://wa.me/${parsedPhoneNum}?text=${encodeURIComponent(messageText)}`;
                window.open(url,'_blank');
              }}>
              <WhatsAppIcon fontSize="inherit" />
            </IconButton>
        </Alert>
      }

        <CardHeader
          classes={{
            title: classes.title,
          }}
          title={this.props.title}
        />
        <CardContent>
          <form className={classes.container}
          onSubmit={(event)=>{
            event.preventDefault();
            this.submit();
          }}>
            <div>
              <TextField
                required
                error={!this.state.isEmail || this.state.emailTaken}
                id="standard-required"
                label={t("Email")}
                type="email"
                value={this.props.details.email}
                onChange={this.handleEmailChange}
                className={classes.textField}
                margin="normal"
              />
              <TextField
                required
                id="standard-required"
                label={t("First Name")}
                value={this.props.details.firstName}
                onChange={this.handleFirstNameChange}

                className={classes.textField}
                margin="normal"
              />
              <TextField
                required
                id="standard-required"
                label={t("Last Name")}
                value={this.props.details.lastName}
                onChange={this.handleLastNameChange}
                className={classes.textField}
                margin="normal"
              />
              <TextField
                error={!this.state.isPhoneNumber}
                id="standard-required"
                label={t("Phone Number")}
                onChange={this.handlePhoneNumberChange}
                value={this.props.details.phoneNum}
                className={classes.textField}
                margin="normal"
              />
              <Button
                disabled={!this.state.isEmail || this.state.emailTaken || this.state.fieldError|| !this.state.info_changed}
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
                endIcon={<SendIcon/>}
              >
              {t("Send")}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card >
    );
  }
}

UserAccountCardSignUp.propTypes = {
  classes:PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(UserAccountCardSignUp));
